import React from 'react';

const Footer =  ({ siteTitle }) => {

  return (
    <footer className="footer">
      <div className="footer-belt">
        <div className="company-sign">
        &copy; 2024 FIND Mapping Limited 
        </div>
        <div className="company-links">
          <a href="../privacy/">Privacy policy | Cookie policy</a><br />
          <a href="../terms/">Terms and conditions</a>
        </div>
        <div className="social-media">
          <a href="https://twitter.com/FINDmaps"><div className="social-twitter">Twitter</div></a>
        </div>
      </div>
    </footer>
  )
};

export default Footer;
